<template>
  <tbody v-if="fillManualFlag">
    <tr v-for="(item, index) in data" :key="index">
      <template>
        <td class="switch-icon" :class="item.loadRow ? '' : 'bg-white px-5/2'" :style="item.status === 200 ? 'background: #E7EEFB !important; pointer-events: none;': ''">
          <span>{{ index+1 }}</span>
          <feather-icon @click="deleteRow(index)" icon="Trash2Icon" svgClasses="w-5 h-5" class="self-center text-red"/>
        </td>
        <td>
          <input class="h-full" @paste.prevent="fillData($event, index, 'full_name')" :disabled="item.status === 200" :style="item.status === 200 ? 'background: #E7EEFB;': ''" 
            v-model="item.customer.full_name" type="text" :class="(validateErrorContainCustomerError(item) && item.errors.error.customer.full_name) ? 'invalid-input' : ''" >
        </td>
        <td>
          <input class="h-full" @paste.prevent="fillData($event, index, 'phone')" :disabled="item.status === 200" :style="item.status === 200 ? 'background: #E7EEFB;': ''" :name="`phone-number-${index}`"
            v-validate="{regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }"
            v-model="item.customer.phone" type="text" :class="(validateErrorContainCustomerError(item) && item.errors.error.customer.phone) || ((item.customer.phone === '' || item.customer.phone === null) && item.customer.full_name !== '') || errors.has(`phone-number-${index}`) ? 'invalid-input' : ''">
        </td>
        <td>
          <input class="h-full" @paste.prevent="fillData($event, index, 'line_1')" :disabled="item.status === 200" :style="item.status === 200 ? 'background: #E7EEFB;': ''"
            v-model="item.customer.address.line_1" @change="autoZoneFlag ? autoZone(item) : ''" type="text" :class="(validateErrorContainCustomerError(item) && item.errors.error.customer.address && item.errors.error.customer.address.line_1) ||  ((item.customer.address.line_1 === '' || item.customer.address.line_1 === null) && item.customer.full_name !== '') ? 'invalid-input' : ''">
        </td>
        <td>
          <template v-if="item.loadRow">
            <div class="animated-input">
              <div class="line rounded-lg h-4"></div>
            </div>
          </template>
          <div class="flex justify-between h-full gap-2" v-else>
            <v-select class="address" name="governorate" :style="item.status === 200 ? 'background: #E7EEFB;pointer-events: none;': ''" 
              v-model="item.customer.address.governorate" v-on:input="item.customer.address.governorate ? loadCities(item, item.customer.address.governorate.id) : item.zones = [], item.cities = [], item.customer.address.city = '', item.customer.address.zone = ''" :options="governorates" label="name" :class="validateErrorContainCustomerError(item) && item.errors.error.customer.address && item.errors.error.customer.address.governorate ? 'invalid-input' : ''"/>
            <v-select class="address" name="city" :style="item.status === 200 ? 'background: #E7EEFB;pointer-events: none;': ''"
              v-model="item.customer.address.city" v-on:input="item.customer.address.city ? loadZones(item, item.customer.address.city.id) : item.zones = [], item.customer.address.zone = ''" :options="item.cities" label="name" :class="validateErrorContainCustomerError(item) && item.errors.error.customer.address && item.errors.error.customer.address.city ? 'invalid-input' : ''"/>
            <v-select class="address" name="zone" :style="item.status === 200 ? 'background: #E7EEFB;pointer-events: none;': ''" v-on:input="fireMixpanelEvent()"
              v-model="item.customer.address.zone" :options="item.zones" label="name" :class="(validateErrorContainCustomerError(item) && item.errors.error.customer.address && item.errors.error.customer.address.zone) || (item.customer.address.zone && item.customer.address.zone.id && item.customer.address.zone.id === 284) || ((item.customer.address.zone === '' || item.customer.address.zone === null) && item.customer.full_name !== '') ? 'invalid-input' : ''"/>
          </div>
        </td>
        <td>
          <v-select class="h-full order-type" v-on:input="checkOrderType(item.type)" :style="item.status === 200 ? 'background: #E7EEFB;pointer-events: none;': ''"
            v-model="item.type" name="order type" :options="types"/>
        </td>
        <td>
          <div class="input-number h-full">
            <input class="text-center h-full" :class="errors.has(`number-of-packages-${index}`) ? 'invalid-input' : ''" v-validate="'numeric|min_value:0|max_value:4'" :name="`number-of-packages-${index}`" :disabled="item.status === 200" :style="item.status === 200 ? 'background: #E7EEFB;': ''"
              v-model="item.number_of_packages" type="number">
          </div>
        </td>
        <td>
          <input class="h-full text-center" @paste.prevent="fillData($event, index, 'cash_amount')" :disabled="item.status === 200" :style="item.status === 200 ? 'background: #E7EEFB;': ''" 
            v-validate="'decimal|min_value:0'" :name="`cash-amount-${index}`"
            :class="errors.has(`cash-amount-${index}`) ? 'invalid-input' : ''"
            v-model.number="item.cash_amount" type="text">
        </td>
        <td>
          <div class="checkbox-input" :style="item.status === 200 ? 'background: #E7EEFB;': ''">
            <vs-checkbox :disabled="item.status === 200" v-model="item.is_customer_allowed_to_open_packages" />
          </div>
        </td>
        <td>
          <div class="checkbox-input" :style="item.status === 200 ? 'background: #E7EEFB;': ''">
            <vs-checkbox :disabled="item.status === 200" v-model="item.fragile" />
          </div>
        </td>
        <td v-for="(extraCol, extraColIndex) in extraCols" :key="extraColIndex">
          <v-select class="h-full order-type" :style="item.status === 200 ? 'background: #E7EEFB;pointer-events: none;': ''" v-if="extraCol.value === 'pickup_point'" name="pickup point" v-model="item.pickup_point" :options="pickupPoints" label="fullAddress"/>
          <div v-else-if="extraCol.value === 'is_counter_dropoff'" :style="item.status === 200 ? 'background: #E7EEFB;pointer-events: none;': ''" class="checkbox-input">
            <vs-checkbox :disabled="item.status === 200" v-model="item[extraCol.value]" />
          </div>
          <input v-validate="item.customer.full_name !== '' && item.type === 'Return' ? 'required' : ''" :name="`notes-${index}`" v-else-if="extraCol.value === 'order_notes'" v-model="item[extraCol.value]" class="h-full" type="text" :disabled="item.status === 200" :style="item.status === 200 ? 'background: #E7EEFB;': ''"
            :class="item.type === 'Return' && item.customer.full_name !== null && item['order_notes'] === '' ? 'invalid-input' : ''">
          <input v-else @paste.prevent="fillData($event, index, extraCol.value)" v-model="item[extraCol.value]" class="h-full" type="text" :disabled="item.status === 200" :style="item.status === 200 ? 'background: #E7EEFB;': ''"
            :class="[validateErrorContainCustomerError(item) && (item.errors.error[extraCol.value] || item.errors.error.customer[extraCol.value] || (item.errors.error.customer.address && item.errors.error.customer.address[extraCol.value])) ? 'invalid-input' : '', extraCol.name === 'Products Declared Value' ? 'text-center' : '']">
        </td>
        <td>
          <div class="h-full" type="text" :disabled="item.status === 200" :style="item.status === 200 ? 'background: #E7EEFB;': ''"></div>
        </td>
      </template>
    </tr>
  </tbody>
</template>

<script>
import vSelect from 'vue-select'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'
import readXlsxFile from 'read-excel-file'
import common from '../../../assets/utils/common'

export default {
  props: [
    'data', 'extraCols', 'sendOrdersFlag', 'uploadFileFlag', 'originFile', 'merchant', 'userMerchant',
    'fillManualFlag', 'deletedFile', 'fileDetails', 'rows', 'replacedFile', 'autoZoneFlag', 'AWBIds', 'optionalCols', 'fillManualPressed'
  ],
  data () {
    return {
      firstSend: true,
      types: ['Delivery', 'Return', 'Exchange', 'Cash Collection'],
      typesDic: {'Delivery': 'delivery-orders', 'Return': 'returns', 'Exchange':  'exchanges', 'Cash Collection': 'cash-collections'},
      governorates: [],
      isReplace: false,
      subscriptionId: '',
      activeIndex: '',
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION
    }
  },
  watch: {
    data (val) {
      if (val) {
        val.forEach(item => {
          if (item.secondary_phone) {
            this.optionalCols[1].checked = true
          } else if (item.line_2) {
            this.optionalCols[0].checked = true
          } else if (item.order_notes) {
            this.optionalCols[2].checked = true
          } else if (item.merchant_order_reference) {
            this.optionalCols[3].checked = true
          } else if (item.is_counter_dropoff) {
            this.optionalCols[4].checked = true
          } else if (item.discount_code) {
            this.optionalCols[6].checked = true
          } 
        })
      }
    },
    uploadFileFlag () {
      this.uploadFile(this.originFile)
    },
    deletedFile () {
      if (this.deletedFile !== '') this.removeItemsFromData(this.deletedFile)
    },
    sendOrdersFlag (val) {
      if (val === true) {
        this.sendOrders()
      }
    },
    'autoZoneFlag' (val) {
      if (val === true) {
        const ordersWithAddress = this.data.filter(item => item.customer.address.line_1)
        ordersWithAddress.forEach(item => {
          this.autoZone(item)
        })
      }
    },
    userMerchant () {
      this.loadPickupPoints()
    }
  },
  methods: {
    checkOrderType (type) {
      if (type === 'Return') {
        this.optionalCols[4].checked = true
      }
    },
    fillData (event, index, type) {
      const fullData = event.clipboardData.getData('text')
      const rawRows = fullData.split('\n')
      const pasteData = []
      let splitCols = {}
      rawRows.forEach((rawRow) => {
        const values = rawRow.split('\t')
        let [name, phone, line_1] = ['', '', '']
        switch (type) {
        case 'full_name':
          [name, phone, line_1] = values
          splitCols = {name, phone, line_1}
          break
        case 'phone':
          [phone, line_1] = values
          splitCols = {phone, line_1}
          break
        case 'line_1':
          [line_1] = values
          splitCols = {line_1}
          break
        default:
          splitCols = {[type]: values}
        }
        pasteData.push(splitCols)
      })
      const extraRowsNumber = this.data.length < (pasteData.length + index + 1) ? (pasteData.length + index) - this.data.length : 0
      this.$emit('addExtraRows', extraRowsNumber)
      switch (type) {
      case 'full_name':
        for (let i = 0; i < pasteData.length; i++) {
          this.data[index].customer.full_name = pasteData[i].name
          if (pasteData[i].phone) this.data[index].customer.phone = pasteData[i].phone
          if (pasteData[i].line_1) {
            this.data[index].customer.address.line_1 = pasteData[i].line_1
            if (this.autoZoneFlag) this.autoZone(this.data[index])
          }
          index++
        }
        break
      case 'phone':
        for (let i = 0; i < pasteData.length; i++) {
          this.data[index].customer.phone = pasteData[i].phone
          if (pasteData[i].line_1) {
            this.data[index].customer.address.line_1 = pasteData[i].line_1
            if (this.autoZoneFlag) this.autoZone(this.data[index])
          }
          index++
        }
        break
      case 'line_1':
        for (let i = 0; i < pasteData.length; i++) {
          this.data[index].customer.address.line_1 = pasteData[i].line_1
          if (this.autoZoneFlag) this.autoZone(this.data[index])
          index++
        }
        break
      default:
        for (let i = 0; i < pasteData.length; i++) {
          this.data[index][type] = pasteData[i][type]
          index++
        }
        break
      }
    },
    deleteRow (rowIndex) {
      this.activeIndex = rowIndex
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: i18nData[this.$i18n.locale]['Are you sure to remove this row?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      this.data.splice(this.activeIndex, 1)
    },
    validateErrorContainCustomerError (order) {
      if (order.errors && order.errors.error && order.errors.error.customer) {
        return true 
      }
    },
    uploadFile (file) {
      if (!this.isExcel(file)) {
        this.$vs.notify({
          color: 'warning',
          title: i18nData[this.$i18n.locale]['Warning'],
          text: 'Only supports upload .xlsx, .xls, .csv suffix files',
          position: 'top-center'
        })
        return false
      }
      this.myProgress = setInterval(() => {
        this.fileDetails.forEach(item => {
          if (item && item.data && item.data.name && file.name && item.data.name === file.name) {
            item.progress = item.progress === 100 ? item.progress : item.progress += 20
          }
        })
      }, 500)
      this.data.forEach((item, index) => {
        if (item.customer.full_name !== '') this.lastFilledRow = index
      })
      let oldDataExistLength = 0
      for (let i = 0; this.data.length > i; i++) {
        if (this.data[i].customer.full_name !== '') oldDataExistLength = i
      }
      oldDataExistLength = (oldDataExistLength === 0 && this.data[0] && this.data[0].customer.full_name === '') || this.data[0] === undefined ? 0 : oldDataExistLength + 1
      readXlsxFile(file).then((rows) => {
        if (rows[0][0] === 'Full Name*' && rows[0][9] === 'Shipment Type' && rows[0][12] === 'Discount Code') {
          rows = rows.slice(1)
          if (!this.fillManualPressed) {
            for (let index = 0; index < rows.length; index++) {
              this.data.push({
                pickup_point: '',
                errors: {},
                cities: [],
                zones: [],
                fileName: '',
                status: 0,
                customer: {
                  full_name: '',
                  phone: '',
                  address: {
                    line_1: '',
                    zone: '',
                    city: '',
                    governorate: ''
                  }
                },
                secondary_phone: '',
                type: '',
                number_of_packages: '',
                cash_amount: '',
                is_customer_allowed_to_open_packages: false,
                fragile: false,
                loadRow: false,
                order_notes: '',
                merchant_order_reference: '',
                is_counter_dropoff: false,
                line_2: '',
                declared_value: '',
                discount_code: ''
              })
            }
          } else {
            const extraRow = rows.length - (this.data.length - oldDataExistLength) 
            for (let index = 0; index < extraRow; index++) {
              this.data.push({
                pickup_point: '',
                errors: {},
                cities: [],
                zones: [],
                fileName: '',
                status: 0,
                customer: {
                  full_name: '',
                  phone: '',
                  address: {
                    line_1: '',
                    zone: '',
                    city: '',
                    governorate: ''
                  }
                },
                secondary_phone: '',
                type: '',
                number_of_packages: '',
                cash_amount: '',
                is_customer_allowed_to_open_packages: false,
                fragile: false,
                loadRow: false,
                order_notes: '',
                merchant_order_reference: '',
                is_counter_dropoff: false,
                line_2: '',
                declared_value: '',
                discount_code: ''
              })
            }
          }
          rows.forEach((row, index) => {
            this.data.forEach(() => {
              this.data[index + oldDataExistLength].customer.full_name = row ? row[0] : ''
              this.data[index + oldDataExistLength].customer.phone = row ? row[1] : ''
              this.data[index + oldDataExistLength].customer.address.line_1 = row ? row[2] : ''
              this.data[index + oldDataExistLength].secondary_phone = row ? row[3] : ''
              this.data[index + oldDataExistLength].cash_amount = row ? row[4] : 0
              this.data[index + oldDataExistLength].declared_value = row ? row[5] : 0
              this.data[index + oldDataExistLength].order_notes = row ? row[6] : ''
              this.data[index + oldDataExistLength].merchant_order_reference = row ? row[7] : ''
              this.data[index + oldDataExistLength].number_of_packages = row ? row[8] === null ?  1 : row[8] : 1
              this.data[index + oldDataExistLength].type = row ? row[9] === null ? this.types[0] : row[9] : this.types[0]
              this.data[index + oldDataExistLength].is_customer_allowed_to_open_packages = row ? row[10] === 'Y' ? true : row[10] === 'N' ? false : '' : false
              this.data[index + oldDataExistLength].is_counter_dropoff = row ? row[11] === 'Y' ? true : row[11] === 'N' ? false : '' : false
              this.data[index + oldDataExistLength].discount_code = row ? row[12] : ''
              this.data[index + oldDataExistLength].fileName = this.originFile.name
            })
            if (this.autoZoneFlag) this.autoZone(this.data[index + oldDataExistLength])
            if (this.data[index + oldDataExistLength].type === 'Return') {
              this.optionalCols[4].checked = true
            }
          })
          if (this.replacedFile) {
            this.deletedFile = this.replacedFile.data.name
            for (let i = this.fileDetails.length - 1; i >= 0; --i) {
              if (this.fileDetails[i].data.name === this.replacedFile.data.name) {
                this.fileDetails.splice(i, 1)
              }
            }
            this.isReplace = true
            this.removeItemsFromData(this.deletedFile)
          }
        } else {
          this.$vs.notify({
            color:'danger',
            title:i18nData[this.$i18n.locale]['Failed'],
            text:i18nData[this.$i18n.locale]['Wrong File!'],
            position: 'top-center'
          })
          this.$emit('deleteFile')
        }
      })
    },
    fireMixpanelEvent () {
      if (this.autoZoneFlag === true) {
        if (this.releaseVersion !== 'staging') {
          this.$mixpanel.track('UPLOAD_V2_AUTOZONING_CHANGE_ZONE', {
            merchant_name: this.merchant.name,
            merchant_email: this.userMerchant.email,
            source: /(android)/i.test(navigator.userAgent)
          })
        }
      }
    },
    removeItemsFromData () {
      const fileRowsCount = this.data.filter(item => item.fileName === this.deletedFile).length
      let indexSplice = 0
      for (let index = 0; index < this.data.length; index++) {
        if (this.data[index].fileName === this.deletedFile) {
          indexSplice = index
          break
        }
      }
      if (this.fillManualPressed && !this.isReplace) for (let index = 0; index < fileRowsCount; index++) {
        this.data.push({
          pickup_point: '',
          errors: {},
          cities: [],
          zones: [],
          fileName: '',
          status: 0,
          customer: {
            full_name: '',
            phone: '',
            address: {
              line_1: '',
              zone: '',
              city: '',
              governorate: ''
            }
          },
          secondary_phone: '',
          type: '',
          number_of_packages: '',
          cash_amount: '',
          is_customer_allowed_to_open_packages: false,
          fragile: false,
          loadRow: false,
          order_notes: '',
          merchant_order_reference: '',
          is_counter_dropoff: false,
          line_2: '',
          declared_value: '',
          discount_code: ''
        })
      }
      this.data = this.data.splice(indexSplice, fileRowsCount)
      this.isReplace = false
      this.$emit('deletedFile', '')
      this.$emit('replacedFile', '')
    },
    isExcel (file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    autoZone (order) {
      const address = {
        full_address: order.customer.address.line_1
      }
      if (address && address.full_address && address.full_address.length > 10) {
        order.loadRow = true
        sendRequest(true, false, this, 'api/v2/gpt/autozoner/', 'post', address, true,
          (response) => {
            order.customer.address = response.data
            order.line_2 = response.data.line_2
            order.customer.address.auto_zoned = response.data.autozoned
            order.customer.address.city = response.data.zone.city
            order.customer.address.governorate = response.data.zone.city.governorate
            this.loadCities(order, order.customer.address.governorate.id)
            this.loadZones(order, order.customer.address.city.id)
            if (order.line_2 !== '.' && order.line_2 !== 'N/A' && order.line_2 !== ' ') {
              this.optionalCols[0].checked = true
            } 
            order.loadRow = false
          }, () => {
            order.loadRow = false
          }
        )
      }
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
      })
    },
    loadCities (order, governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        order.cities = results.data
      })
    },
    loadZones (order, cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        order.zones = results.data.filter(item => item.id !== 284)
      })
    },
    loadMerchant () {
      sendRequest(true, false, this, `api/v1/users/merchants/${this.$store.state.AppActiveUser.uid}/`, 'get', null, true,
        (response) => {
          localStorage.setItem('userMerchant', JSON.stringify(response.data))
          this.loadMerchantData(response.data)
        }
      )
    },
    loadMerchantData (merchant) {
      sendRequest(true, false, this, `api/v1/merchants/${merchant.merchant}/`, 'get', null, true,
        (response) => {
          localStorage.setItem('merchant', JSON.stringify(response.data))
          this.subscriptionId = response.data.subscription.id
        }
      )
    },
    loadPickupPoints () {
      sendRequest(true, false, this, `api/v1/merchants/${this.userMerchant.merchant}/pickup-points/?limit=60`, 'get', null, true, 
        (response) => {
          this.pickupPoints = response.data.results
          this.pickupPoints.forEach(element => {
            element.fullAddress = `${`${element.address.line_1  } ${  element.address.line_2} `}${i18nData[this.$i18n.locale][element.address.zone.name] ? i18nData[this.$i18n.locale][element.address.zone.name] : element.address.zone.name}` 
          })
        }
      )
    },
    sendOrders () {
      const ordersWithNames = this.data.filter(item => item.customer.full_name !== '')
      const ordersWithData = this.data.filter(item => item.customer.full_name !== '' && item.customer.address.zone && item.customer.address.zone.id !== 284 && item.customer.address.line_1 !== '' && /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/.test(item.customer.phone))
      this.$validator.validateAll().then(result => {
        if (result && ordersWithNames.length === ordersWithData.length) {
          const promises = []
          this.data = this.data.sort(function (row, secondRow) { return secondRow.status - row.status })
          if (!this.firstSend) {
            for (let index = 0; index < this.data.length; index++) {
              const shiftError = this.data[index]
              if (shiftError.customer.full_name && shiftError.status === 0) {
                this.data.splice(index, 1)
                this.data.unshift(shiftError)
              }
            }
          }
          this.firstSend = false
          for (let index = 0; index < this.data.length; index++) {
            const order = this.data[index]
            if (order.customer.full_name && order.status !== 200) {
              promises.push(this.sendOrderToShipBluWithPromise(order))
            }
          }
          Promise.allSettled(promises)
            .then((results) => {
              results.forEach((item, index) => {
                this.data[index].status = item.status === 'rejected' ? 400 : item.status === 'fulfilled' ? 200 : 200
                this.data[index].errors = item.reason
              })
              this.$emit('data', this.data)
              this.data = this.data.sort(function (row, secondRow) { return secondRow.status - row.status })
            })
            .catch(() => {
            })
        }
      })
    },
    sendOrderToShipBluWithPromise (orderData) {
      orderData.loadRow = true
      return new Promise(async (resolve, reject) => {
        try {
          const orderBody = {
            customer: {
              full_name: orderData.customer.full_name,
              phone: orderData.customer.phone,
              secondary_phone: orderData.secondary_phone ? String(orderData.secondary_phone) : null,
              address: {
                auto_zoned: orderData.customer.address.auto_zoned,
                line_1: orderData.customer.address.line_1,
                line_2: orderData.line_2 === '' ? '.' : orderData.line_2,
                zone: orderData.customer.address.zone ? orderData.customer.address.zone.id : ''
              }
            },
            is_customer_allowed_to_open_packages: orderData.is_customer_allowed_to_open_packages ? orderData.is_customer_allowed_to_open_packages : false,
            cash_amount: Number(orderData.cash_amount),
            merchant_order_reference: orderData.merchant_order_reference ? orderData.merchant_order_reference : '',
            packages: [],
            order_notes: orderData.order_notes === null ? '' : orderData.order_notes,
            is_counter_dropoff: orderData.is_counter_dropoff ? orderData.is_counter_dropoff : false,
            declared_value: orderData.declared_value === '' ? 0 : Number(orderData.declared_value), 
            discount_code: orderData.discount_code,
            number_of_packages: orderData.number_of_packages === '' ? 1 : orderData.number_of_packages
          }
          if (orderData.pickup_point) {
            orderBody.pickup_point = orderData.pickup_point ? orderData.pickup_point.id : null
          }
          if (orderData.type === 'Cash Collection') {
            orderBody.amount = Number(orderData.cash_amount)
          } else {
            for (let index = 0; index < Number(orderBody.number_of_packages); index++) {
              orderBody.packages.push({
                description: '',
                fragile: orderData.fragile,
                package_size: 1
              })
            }
          }
          if (orderData.type !== 'Exchange') {
            sendRequest(true, false, this, `api/v1/${orderData.type ? this.typesDic[orderData.type] : 'delivery-orders'}/?ref=frontend-excel-sheet`, 'post', orderBody, true,
              (response) => {
                this.AWBIds.push(response.data.tracking_number)
                orderData.loadRow = false
                resolve({
                  status: 200
                })
              },
              (error) => {
                orderData.loadRow = false
                reject({
                  status: 400,
                  error: error.response.data
                })
              }
            )
          } else {
            sendRequest(true, false, this, 'api/v2/exchanges/?ref=frontend-excel-sheet', 'post', orderBody, true,
              () => {
                orderData.loadRow = false
                resolve({
                  status: 200
                })
              }, (error) => {
                orderData.loadRow = false
                reject({
                  status: 400,
                  error: error.response.data
                })
                this.$vs.loading.close()
              })
          }
        } catch (error) {
          orderData.loadRow = false
          reject({
            status: 400,
            error: {}
          })
        }
      })
    }
  },
  components: {
    vSelect
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.subscriptionId = user.subscription.id
    })
    this.loadGovernorates()
  }
}
</script>
<style lang="scss">
.switch-icon {
  position: relative;
  cursor: pointer;
  span:nth-child(2) {
    display: none;
    position: absolute !important;
    top: 8.5px;
    left: 8.5px;
  }
  &:hover {
    border-radius: 6px;
    background: rgba(226, 127, 131, 0.26) !important;
    span:nth-child(1) {
      display: none;
    }
    span:nth-child(2) {
      display: block;
    }
  }
}
.animated-input {
  height: 35px;
  background: rgba(231, 231, 231, 0.81) !important;
  backdrop-filter: blur(7.5px);
  border-radius: 6px;
  padding: 6px;
}
@mixin background-gradient {
  background-image: linear-gradient(45deg, #0A326630 0px, rgba(229, 229, 229, 0.8) 40px, #0A326630 80px);
}
.line{
  margin-top: 4px;
  @include background-gradient;
  animation: shine-lines 1s infinite alternate;
}
@keyframes shine-lines {
  0% {background-position: 200px;}
  100% {background-position: -200px;}
}
</style>