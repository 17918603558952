<template>
  <div id="merchant-page">
    <div class="flex gap-1 flex-wrap items-center mb-5">
      <router-link class="flex" :to="`/${$route.params.lang}/merchant/dashboard`">
        <feather-icon svgClasses="w-5" icon="HomeIcon"></feather-icon>
      </router-link>
      <div v-if="prevRoute && prevRoute.includes('orders')" class="flex items-center gap-1">
        <feather-icon svgClasses="w-4" icon="ChevronsRightIcon"></feather-icon>
        <router-link class="flex" :to="prevRoute">
          <p class="leading-none capitalize">{{prevRoute.split('/')[4].includes('-') ? $t(prevRoute.split('/')[4].split('-').join(' ')) : $t(prevRoute.split('/')[4])}}</p>
        </router-link>
      </div>
      <div class="flex items-center gap-1">
        <feather-icon svgClasses="w-4" icon="ChevronsRightIcon"></feather-icon>
        <p class="leading-none font-medium">{{ $t('Upload Orders') }}</p>
      </div>
    </div>
    <div class="flex items-center upload-file px-6 py-3 mb-4">
      <feather-icon icon="AlertCircleIcon" svgClasses="h-8 w-8 text-blue-100"/>
      <p class="ml-2 text-sm font-semibold">
        {{$t('For help and guidance on how to upload multiple orders, check out our ')}} 
        <a target="blank" v-if="$i18n.locale === 'en'" href="https://support.shipblu.com/en/support/solutions/articles/154000207438-upload-multiple-orders-delivery-return-exchange-cash-collection-" class="text-blue-100 underline">{{$t('knowledge center')}}.</a>
        <a target="blank" v-else href="https://support.shipblu.com/ar/support/solutions/articles/154000207438-%D8%AA%D8%AD%D9%85%D9%8A%D9%84-%D8%B7%D9%84%D8%A8%D8%A7%D8%AA-%D9%85%D8%AA%D8%B9%D8%AF%D8%AF%D8%A9-%D8%AA%D9%88%D8%B5%D9%8A%D9%84%D8%8C-%D8%A5%D8%B1%D8%AC%D8%A7%D8%B9%D8%8C-%D8%A5%D8%B3%D8%AA%D8%A8%D8%AF%D8%A7%D9%84%D8%8C-%D8%AA%D8%AD%D8%B5%D9%8A%D9%84-%D9%86%D9%82%D8%AF%D9%8A-" class="text-blue-100 underline">{{$t('knowledge center')}}.</a>
      </p>
    </div>
    <div class="upload-file-border text-center p-4" :class="fillManualFlag ? 'lg:flex items-center justify-between' : ''">
      <div :class="fillManualFlag ? 'lg:flex items-center gap-6' : ''">
        <img src="../../assets/images/pages/upload-orders-logo.svg" height="50" width="80"/>
        <input type="file" class="hidden" ref="fileInput" accept=".xlsx, .xls" @change="handleClick">
        <div
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="cursor-pointer text-base text-center">
          <div class="pb-2 text-sm font-semibold">
            <div class="flex items-center gap-2 justify-center">
              <feather-icon icon="UploadCloudIcon" svgClasses="h-8 w-8 text-blue-100"/>
              <p>
                <span class="font-semibold text-primary" @click.stop="$refs.fileInput.click()">{{$t('Click To Upload') + ' '}}</span>
                <span class="text-black font-semibold">{{$t('or drag and drop your orders sheet')}}</span>
              </p>
            </div>
            <p class="text-darkgray font-semibold">{{$t('Supports files of .xls and .xlsx formats')}}</p>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="flex items-center justify-center gap-2">
          <vs-button class="p-0 font-semibold text-sm" @click="uploadFiletype('uploadShipments')">
            <div class="flex gap-2 items-center py-2 px-3">
              <p>{{$t('Upload File')}}</p>
              <p class="material-icons-outlined text-xl">upload_file</p>
            </div>
          </vs-button>
          <vs-button class="p-0 font-semibold text-sm">
            <div class="flex gap-2 items-center py-2 px-3" @click="fillManual(), mixPanelActivate('UPLOAD_V2_FILL_MANUALLY')">
              <p>{{$t('Fill Manually')}}</p>
              <p class="material-icons-outlined text-xl">touch_app</p>
            </div>
          </vs-button>
          <vs-button class="p-0 font-semibold text-sm" @click="uploadFiletype('uploadAutoZoning')">
            <div class="flex gap-2 items-center py-2 px-3">
              <p>{{$t('Autozoning Upload')}}</p>
              <p class="material-icons-outlined text-xl">upload_file</p>
            </div>
          </vs-button>
        </div>
      </div>
    </div>
    <div v-for="(fileDetail, index) in fileDetails" :key="index" class="upload-file-box p-3 w-full mt-5">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-between">
          <div class="rounded-full p-1 h-12 w-12 flex items-center justify-center" style="background: #EDF4FB;">
            <feather-icon icon="FileTextIcon" svgClasses="h-6 w-6" style="color:#1C5BFE;"/>
          </div>
          <div class="ml-2">
            <p class="text-lg font-medium"> {{fileDetail.data ? fileDetail.data.name : ''}} </p>
            <p class="text-sm font-medium text-grey"> {{fileDetail.data ? fileDetail.data.size / 1000 : '' }} KB</p>
          </div>
        </div>
        <div class="flex">
          <div class="cursor-pointer mr-6" @click="replaceFile(fileDetail), $refs.fileInput.click()">
            <div class="upload-action flex px-3 py-2">
              <feather-icon icon="RepeatIcon" svgClasses="w-4 h-4" class="self-center"/>
              <span class="text-sm ml-2">{{ $t('Replace File') }}</span>
            </div>
          </div>
          <div class="cursor-pointer" @click="deleteFile(fileDetail)">
            <div class="upload-action-delete flex px-2 py-2">
              <feather-icon icon="Trash2Icon" svgClasses="w-5 h-5" class="self-center text-red"/>
            </div>
          </div>
        </div>
      </div>
      <div class="w-5/6 m-auto text-center flex items-center mt-3">
        <vs-progress :percent="fileDetail.progress" color="primary"></vs-progress>
        <p class="ml-3">{{fileDetail.progress}}%</p>
      </div>
    </div>
    <upload-orders-table :replacedFile="replacedFile" @replacedFile="replacedFile = $event" :fillManualPressed="fillManualPressed" :merchant="merchant" :userMerchant="userMerchant" :fillManualFlag="fillManualFlag" :originFile="originFile"
      :deletedFile="deletedFile" @deletedFile="deletedFile = $event" :uploadFileFlag="uploadFileFlag" :fileDetails="fileDetails" @fileDetails="fileDetails = $event"
      @deleteFile="deleteFile" :merchantInsurance="merchantInsurance"></upload-orders-table>
    <upload-shipments-modal :uploadFileType="uploadFileType" @upLoadAutoZoning="upLoadAutoZoning" :uploadShipmentsModal="uploadShipmentsModal" :merchant="merchant" :userMerchant="userMerchant" @uploadShipmentsModal="uploadShipmentsModal = $event" />
  </div>
</template>

<script>
import UploadOrdersTable from './components/UploadOrdersTable.vue'
import UploadShipmentsModal from './components/UploadShipmentsModal.vue'
import {sendRequest} from '../../http/axios/requestHelper.js'
import common from '../../assets/utils/common'

export default {
  data () {
    return {
      uploadShipmentsModal: false,
      uploadFileType: '',
      fillManualFlag: false,
      fileDetails: [],
      file_URl: '',
      originFile: {},
      uploadFileFlag: false,
      fillManualPressed: false,
      deletedFile: '',
      replacedFile: '',
      merchantInsurance: {
        insurance_package: {}
      },
      userMerchant:{},
      merchant:{},
      helpfulArticleURL: 'https://shipblu.freshdesk.com/a/solutions/articles/80001070415',
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      prevRoute: null
    }
  },
  methods: {
    uploadFiletype (type) {
      this.uploadFileType =  type
      this.uploadShipmentsModal = true
    },
    resetInputloader () {
      this.$refs.fileInput.value = ''
    },
    fillManual () {
      this.fillManualPressed = true
      this.fillManualFlag = true
    },
    handleClick (e) {
      const files = e.target.files
      if (e.target.files.length) { 
        const rawFile = files[0]
        this.fileDetails.push({
          data: rawFile,
          progress : 0
        })
        this.fillManualFlag = true
        if (!rawFile) return
        this.originFile = rawFile
        this.uploadFileFlag = !this.uploadFileFlag
      }
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .xlsx, .xls, .csv suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
      this.fileDetails.push({
        data: rawFile,
        progress : 0
      })
      this.originFile = rawFile
      this.uploadFileFlag = !this.uploadFileFlag
      this.fillManualFlag = true
    },
    upLoadAutoZoning (rawFile) {
      this.fileDetails.push({
        data: rawFile,
        progress : 0
      })
      this.originFile = rawFile
      this.uploadFileFlag = !this.uploadFileFlag
      this.fillManualFlag = true
    },
    isExcel (file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    deleteFile (fileDetail) {
      this.deletedFile = fileDetail.data ? fileDetail.data.name : null
      for (let i = this.fileDetails.length - 1; i >= 0; --i) {
        if (this.fileDetails[i].data.name === this.deletedFile) {
          this.fileDetails.splice(i, 1)
          if (this.fileDetails.length === i) this.resetInputloader()
        }
      }
      if (this.releaseVersion !== 'staging') {
        this.$mixpanel.track('UPLOAD_V2_DELETE_FILE', {
          merchant_name: this.merchant.name,
          merchant_email: this.userMerchant.email,
          source: /(android)/i.test(navigator.userAgent)
        })
      }
    },
    mixPanelActivate (type) {
      if (this.releaseVersion !== 'staging') {
        this.$mixpanel.track(type, {
          merchant_name: this.merchant.name,
          merchant_email: this.userMerchant.email,
          source: /(android)/i.test(navigator.userAgent)
        })
      }
    },
    replaceFile (fileDetail) {
      this.replacedFile = fileDetail
      if (this.releaseVersion !== 'staging') {
        this.$mixpanel.track('UPLOAD_V2_REPLACE_FILE', {
          merchant_name: this.merchant.name,
          merchant_email: this.userMerchant.email,
          source: /(android)/i.test(navigator.userAgent)
        })
      }
    },
    loadMerchantInsurance () {
      sendRequest(true, false, this, `api/v1/merchants/${this.userMerchant.merchant}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.merchantInsurance = response.data
        }        
      )
    }
  },
  components: {
    UploadOrdersTable,
    UploadShipmentsModal
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from.path
    })
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.merchant = results.merchantData
      this.userMerchant = results.userMerchantData
      this.loadMerchantInsurance()
    })
  }
}
</script>

<style lang="scss">
.upload-file-border {
  background: #EDF4FB;
  border-radius: 14px !important;
  border: 2px dashed rgba(28, 91, 254, 0.26) !important;
}
.upload-file-box {
  background: #fff;
  border-radius: 8px !important;
  border: 2px solid #CBE4FD !important;
}
.upload-file {
  background: #EDF4FB;
}
.upload-action {
  font-weight: 500;
  background: #fff;
  border-radius: 6px;
  color: #1C5BFE;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
}
.upload-action-delete {
  font-weight: 500;
  background: #fff;
  border-radius: 6px;
  color:#D21C1C;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
  border: 2px solid #D21C1C !important;
}
</style>
